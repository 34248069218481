import React, { useState, useEffect, useRef } from "react";
import {
  Accordion,
  Alert,
  Button, Card,
  Col,
  Form,
  Row,
} from "react-bootstrap";
import swal from 'sweetalert';
import { useSubheader } from "@metronic/layout";
import {getMedicById, updateMedicById} from "../../services/medicsSelfRegistrationService";
import {useHistory} from "react-router-dom";

const MedicSelfRegistrationsDetails = (props) => {
  const subheader = useSubheader();

  const { slug } = props.match.params;

  const [medic, setMedic] = useState({});

  const history = useHistory();

  useEffect(() => {
    getMedicById(slug).then(response => {
      setMedic(response.data);
    });
    subheader.setTitle("Solicitud de registros de medicos");
  }, [slug]);

  const onClickAuthorize = async () => {
    swal({
      title: "¿Estás seguro?",
      text: "¿Deseas autorizar a este médico?",
      icon: "warning",
      buttons: ["Cancelar", "Autorizar"],
      dangerMode: true,
    }).then((willAuthorize) => {
      if (willAuthorize) {
        updateMedicById(medic?.id, 'y').then(response => {
          swal("Autorizado", "Se ha autorizado el médico", "success");
          history.push("/solicitudes_registros_medicos");
        });
      } else {
        swal("Cancelado", "No se ha realizado ningún cambio", "info");
      }
    });
  }

  const onClickDecline = async () => {
    updateMedicById(medic?.id, 'n').then(response => {
      swal("Rechazado", "No se ha autorizado el medico", "error");

      history.push("/solicitudes_registros_medicos");
    });
  }

  return (
      <>
        <Alert
            variant="success"
            className="text-center"
            style={{ fontSize: "16px" }}
        >
          Solicitud de registros de medicos
        </Alert>


        <Row>
          <Col>
            <Card>
              <Card.Body>
                <>
                  <b>Nombre: </b>
                  {`${medic.name} ${medic.f_lastname} ${medic.m_lastname}`}
                </>
                <br/>
                <>
                  <b>Genero: </b>
                  {medic.genre}
                </>
                <br/>
                <>
                  <b>Fecha de nacimiento: </b>
                  {medic.birthday}
                </>
                <br/>
                <>
                  <b>Ced. Profesional: </b>
                  {medic.professional_license}
                </>
                <br/>
                <>
                  <b>Medico Red: </b>
                  {medic.network_medic === 'y' ? 'SI' : 'NO'}
                </>
                <br/>
              </Card.Body>
            </Card>
          </Col>

          <Col>
            <Card>
              <Card.Body>
                <>
                  <b>Hospital: </b>
                  {medic.hospital}
                </>
                <br/>
                <>
                  <b>Especialidad: </b>
                  {medic.specialty}
                </>
                <br/>
                <>
                  <b>Datos de contacto: </b>
                  {medic.contact_cellphone} {medic.contact_phone} {medic.contact_consulting_room}
                </>
                <br/>
                <>
                  <b>Correo electronico: </b>
                  {medic.email}
                </>
                <br/>
                <>
                  <b>Domicilio: </b>
                  {medic.address_street} {medic.address_suburb} {medic.address_cp}
                </>
                <br/>
              </Card.Body>
            </Card>
          </Col>
        </Row>


        <Row>
          <Col className="mt-5">
            <Button variant="success" onClick={onClickAuthorize}>
            Autorizar
            </Button>

            <Button variant="danger" onClick={onClickDecline} className="ml-3">
              Rechazar
            </Button>
          </Col>
        </Row>
      </>
  );
};

export default MedicSelfRegistrationsDetails;
