import axios from "axios";

export const GET_ALL = "medics_self_registrations";

export function getAllInAuthorize(pageNumber) {
    return axios.get(`${GET_ALL}?page=${pageNumber}`);
}

export function getMedicById(medicId) {
    return axios.get(`${GET_ALL}/${medicId}`);
}

export function updateMedicById(medicId, authorized) {
    return axios.put(`${GET_ALL}/${medicId}?authorized=${authorized}`);
}


export function getAllInAuthorizeFilter(body, pageNumber) {
    return axios.post(`${GET_ALL}?page=${pageNumber}`, body);
}
