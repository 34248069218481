import React, { useMemo } from "react";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { Icon } from "@material-ui/core";
import { checkIsActive, IfHasPermission } from "@metronic/_helpers";
import { useState } from "react";
import { useEffect } from "react";

export function AsideInsuranceMenu({ isActive, modulesCounter }) {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };

  const [casesControlDesk, setCasesControlDesk] = useState([]);
  const [casesCostControl, setCasesCostControl] = useState([]);
  const [casesMedicalOpinion, setCasesMedicalOpinion] = useState([]);
  const [casesCancellations, setCasesCancellations] = useState([]);

  useEffect(() => {
    setCasesControlDesk(modulesCounter.control_desk);
    setCasesCostControl(modulesCounter.cost_control);
    setCasesMedicalOpinion(modulesCounter.medical_opinion);
    setCasesCancellations(modulesCounter.finished_cases);

    
  }, [modulesCounter]);

  return (
    <div className={`tab-pane fade ${isActive && "show active"}`}>
      <div className="aside-menu-wrapper flex-column-fluid px-10 py-5">
        {/* begin::Menu Container */}
        <div
          id="kt_aside_menu"
          data-menu-vertical="1"
          className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
          {...layoutProps.asideMenuAttr}
        >
          {/* begin::Menu Nav */}
          <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {IfHasPermission("Mesa de Control") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/mesa_control",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/mesa_control">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-table" />
                  </span>
                  <span className="menu-text">Mesa de Control</span>
                  <span className="badge badge-secondary">{casesControlDesk}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Dictamen Medico") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/dictamen_medico",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/dictamen_medico">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-notes-medical" />
                  </span>
                  <span className="menu-text">Dictamen Medico</span>
                  <span className="badge badge-secondary">{casesMedicalOpinion}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Control de Costos") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/control_costos",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/control_costos">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-dollar-sign" />
                  </span>
                  <span className="menu-text">Control de Costos</span>
                  <span className="badge badge-secondary">{casesCostControl}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("24/7 Contigo") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/24_7_contigo",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/24_7_contigo">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-user-check" />
                  </span>
                  <span className="menu-text">24/7 Contigo</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Consulta General") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/consulta_general",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/consulta_general?modo=aseguradora">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-book-medical" />
                  </span>
                  <span className="menu-text">Consulta General</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Dictamen Extemporaneo") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/dictamen_extemporaneo",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/dictamen_extemporaneo">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-file-invoice-dollar" />
                  </span>
                  <span className="menu-text">Dictamen Extemporáneo</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Solicitudes") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/solicitudes",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/solicitudes">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-lock-open" />
                  </span>
                  <span className="menu-text">Solicitudes</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Casos Finalizados") && (
              <li
                className={`menu-item ${getMenuItemActive(
                  "/casos_finalizados",
                  false
                )}`}
              >
                <NavLink className="menu-link" to="/casos_finalizados">
                  <span className="svg-icon menu-icon">
                    <Icon className="fa fa-check" />
                  </span>
                  <span className="menu-text">Casos finalizados</span>
                  <span className="badge badge-secondary">{casesCancellations}</span>
                </NavLink>
              </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Cancelaciones") && (
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/cancelaciones",
                        false
                    )}`}
                >
                  <NavLink className="menu-link" to="/cancelaciones">
                    <span className="svg-icon menu-icon">
                      <Icon className="fa fa-times" />
                    </span>
                    <span className="menu-text">Cancelaciones</span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Programaciones") && (
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/programaciones",
                        false
                    )}`}
                >
                  <NavLink className="menu-link" to="/programaciones">
                    <span className="svg-icon menu-icon">
                      <Icon className="fa fa-file-invoice-dollar" />
                    </span>
                    <span className="menu-text">Programaciones</span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}

            {/*begin::1 Level*/}
            {IfHasPermission("Solicitudes Registros Medicos") && (
                <li
                    className={`menu-item ${getMenuItemActive(
                        "/solicitudes_registros_medicos",
                        false
                    )}`}
                >
                  <NavLink className="menu-link" to="/solicitudes_registros_medicos">
                    <span className="svg-icon menu-icon">
                      <Icon className="fas fa-pump-medical" />
                    </span>
                    <span className="menu-text">Solicitudes Registros Medicos</span>
                  </NavLink>
                </li>
            )}
            {/*end::1 Level*/}
          </ul>

          {/* end::Menu Nav */}
        </div>
        {/* end::Menu Container */}
      </div>
    </div>
  );
}
