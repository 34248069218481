import React, { useEffect, useState } from "react";
import { ErrorMessage, Formik } from "formik";
import {
  Alert,
  Button,
  Form,
  Spinner,
  Card,
  Row,
  Col,
  Table,
  Accordion,
  InputGroup,
} from "react-bootstrap";
import NumberFormat from 'react-number-format';
import moment from "moment";
import * as Yup from "yup";
import { getInputClasses } from "@utils/getInputclasses";
import AlertError from "@components/AlertError";
import { Divider } from "@material-ui/core";
import { GetUser } from "@metronic/_helpers";
import { getLetterMovsByCase, storeLetterMov, updateLetterMvByCase } from "@services/letterMovsService";
import { shallowEqual, useSelector } from "react-redux";
import {isNotEmpty} from "@utils/checkObjectEmpty";
import {getMedicalNetworkAll} from "../../services/medicalNetworkService";

export const AuthLetters = (props) => {
  return (
    <>
      <Card>
        <Card.Header>
          <Accordion.Toggle
            as={Button}
            variant="link"
            eventKey={props.eventKey}
          >
            Cartas de Autorizacion
          </Accordion.Toggle>
          <i className={`fa ${props.icon}`} style={{position:"absolute", right:"0", marginRight:"15px", marginTop:"10px" }}></i>
        </Card.Header>
        <Accordion.Collapse eventKey={props.eventKey}>
          <Card.Body>
            <ContentAuthLetters {...props} />
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </>
  );
};

export const ContentAuthLetters = (props) => {
  return (
    <>
      <Alert
        variant="dark"
        className="text-center mt-4"
        style={{ fontSize: "14px" }}
      >
        {props.titleAlert ? props.titleAlert : 'Cartas de Autorizacion'}
      </Alert>

      <Card>
        <Card.Body>
          <FormAuthLetters {...props} />
        </Card.Body>
      </Card>
    </>
  );
};

const FormAuthLetters = ({ hideAmountLetter = false, ...props }) => {
  const { data } = props;
  const [isSubstitution, setIsSubstitution] = useState(false);
  const [userAuth] = useState({ ...GetUser().user });
  const [letterMovs, setletterMovs] = useState([]);
  const [inputKey, setInputKey] = useState("");
  const [showDoctorNetworkAxa, setShowDoctorNetworkAxa] = useState(false);
  const [medicalNetworkDoctors, setMedicalNetworkDoctors] = useState([]);

  moment.locale('es');

  const amountRequired = Yup.string().required("El campo es requerido");

  const validationSchema = Yup.object().shape({
    file: Yup.mixed()
      .required("El campo es requerido")
      .test("fileFormat", "Solo se permiten archivos PDF", (value) => {
        return value && ["application/pdf"].includes(value.type);
      }),
    amount: !props.typeModule && props.typeModule !== "Honorarios medicos" ? amountRequired : null,
    letterIden: Yup.string().required("El campo es requerido"),
    letterType: Yup.string().required("El campo es requerido"),
  });

  const formatToCurrency = amount => "$" + amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  const calculateTotalLetters = () => {
    var lastAuthLetter = letterMovs?.filter( letterMov => letterMov.letterType === "Carta de autorizacion").pop();
    let total = letterMovs?.reduce( (total, current) => !current.ignored && current.letterType !== "Carta de autorizacion" ? total = total + Number(current.amount.replace(/[^0-9.-]+/g,"")) : total, 0);
    if (lastAuthLetter && !lastAuthLetter?.ignored)
      return total + Number(lastAuthLetter?.amount.replace(/[^0-9.-]+/g,"")) || 0;
    else
      return total;
  }

  let counterNumberLetter = letterMovs.length + 1;
  let totalLetters = calculateTotalLetters();

  useEffect(() => {
    if (isNotEmpty(data)) {
      getAllByCase();
      getMedicalNetwork();
    }
  }, [data]);

  useEffect(() => {
    props.setTotalLetters(totalLetters);
  }, [totalLetters, props]);

  const getAllByCase = () =>  {
    getLetterMovsByCase(data.id).then(data => {
      if (props.typeModule && props.typeModule === "Honorarios medicos") {
        data.data = data.data?.filter( currLetterMov => currLetterMov.typeModule === "Honorarios medicos");
      } else {
        data.data = data.data?.filter( currLetterMov => currLetterMov.typeModule !== "Honorarios medicos");
      }

      setletterMovs(data.data);
    });
  }

  const getMedicalNetwork = () =>  {
    getMedicalNetworkAll().then(data => setMedicalNetworkDoctors(data.data));
  }

  const initialDocument = {
    file: "",
    letterType: "",
    amount: "",
    letterIden: counterNumberLetter,
    letterSustitution: "",
    letterSustitutionL: "",
    doctorName: "",
  };

  const {token} = useSelector(
    ({auth}) => ({
        token: auth.authToken,
    }),
    shallowEqual
  );

  const downloadPDF = (e, urlFile) => {
    e.preventDefault();
    fetch(urlFile, {
      headers: {
        'Authorization': `Bearer ${token}`, 
      }
    })
    .then(response => {
      response.blob().then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.target = "_blank";
        a.click();
      });
    })
  }

  const onSubmit = (values, { setStatus, setSubmitting, resetForm }) => {
    setTimeout(() => {
      const wasIgnored = letterMovs.filter(letterMov => letterMov.ignored)
          .some(letterMov => letterMov.letterMovId === parseInt(values.letterSustitution));

      if (wasIgnored) {
        setStatus('Ocurrio algun error al guardar: ya fue sustituida la carta');
        setSubmitting(false);
        return;
      }


      let formData = new FormData();
      formData.append("file", values.file);
      formData.append("userId", userAuth.idUser);
      formData.append("letterType", values.letterType);
      formData.append("amount", values.amount);
      formData.append("letterIden", values.letterIden);
      formData.append("caseId", data.id);
      formData.append("typeModule", props.typeModule || "Cartas de autorizacion");
      formData.append("doctorName", values.doctorName);
      formData.append("isAxaMedic", values.isAxaMedic || null);
      if (values.medicalNetworkId) {
        formData.append("medicalNetworkId", values.medicalNetworkId || null);
      }
      storeLetterMov(formData)
        .then( async response => {
          if (values.letterType === "Sustitucion") {
            await updateLetterMvByCase(values.letterSustitution, { ignored: true });
            setIsSubstitution(false);
          }
          await getAllByCase();
          if (props.setTotalLetters) {
            props.setTotalLetters(calculateTotalLetters());
          }
          setInputKey(Math.random().toString(36));
        })
        .catch( err => setStatus(`Ocurrio algun error al guardar`));

      resetForm();
      setSubmitting(false);
    }, 1000);
  };

  return (
    <>
      <Table responsive striped bordered hover>
        <thead>
          <tr>
            <th>Documento</th>
            <th>Usuario</th>
            <td hidden={(!props.typeModule && props.typeModule !== "Honorarios medicos") || props.hideMedicField}>Medico tratante</td>
            <th>Fecha</th>
            <th>Tipo de Carta</th>
            <th>Numero de Carta</th>
            <th hidden={hideAmountLetter}>Monto de Carta</th>
            <th>Opciones</th>
          </tr>
        </thead>
        <tbody>
          {letterMovs?.map( (letterMovement, idx) => (
            <tr key={idx} style={{ backgroundColor: letterMovement.ignored ? "#ff6b6b" : "" }}>
              <td>Carta de Autorizacion</td>
              <td>{letterMovement.user?.userName}</td>
              <td hidden={(!props.typeModule && props.typeModule !== "Honorarios medicos") || props.hideMedicField}>{letterMovement.doctorName}</td>
              <td>{moment(letterMovement.createdAt).format('DD/MM/YYYY HH:mm:ss A')}</td>
              <td>{letterMovement.letterType}</td>
              <td>{letterMovement.letterIden}</td>
              <td hidden={hideAmountLetter}>${letterMovement.amount}</td>
              <td>
                <a hidden={letterMovement.ignored} href={letterMovement.urlFile} onClick={(e) => {downloadPDF(e, letterMovement.urlFile)}}>
                  <i className="fas fa-eye"></i>
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="text-lg-right" hidden={hideAmountLetter}>
        Total Cartas: {formatToCurrency(totalLetters)}
      </div>

      <Divider className="mt-5 mb-5" />
      { props.showForm && <Formik
        initialValues={initialDocument}
        validationSchema={validationSchema}
        enableReinitialize={true}
        onSubmit={onSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          setFieldValue,
          status,
          isSubmitting,
        }) => (
          <>
            <AlertError status={status} />

            <Form onSubmit={handleSubmit}>
              <Row hidden={props.disabledAll}>
                <Col>
                  <Form.Group controlId="file">
                    <Form.Label>Archivo</Form.Label>
                    <Form.Control
                      key={inputKey || ''}
                      disabled={props.disabledAll}
                      type="file"
                      name="file"
                      accept="application/pdf"
                      onChange={(event) => {
                        setFieldValue("file", event.currentTarget.files[0]);
                      }}
                      className={`${getInputClasses(touched, errors, "file")}`}
                    />
                    <ErrorMessage name="file">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>

                <Col>
                  <Form.Group controlId="letterType">
                    <Form.Label>Tipo de Carta *</Form.Label>
                    <Form.Control
                      disabled={props.disabledAll}
                      as="select"
                      name="letterType"
                      value={values.letterType}
                      onChange={(e) => {
                        handleChange(e);
                        e.target.value === "Sustitucion" ? setIsSubstitution(true) : setIsSubstitution(false);
                      }}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "letterType"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      <option hidden={props.typeModule !== "Honorarios medicos"} value="Carta honorarios medicos">Carta honorarios medicos</option>
                      <option hidden={props.typeModule === "Honorarios medicos"} value="Carta de autorizacion">CARTA DE AUTORIZACIÓN</option>
                      <option hidden={props.typeModule === "Honorarios medicos" || props.hideComplement} value="Complementaria">Complementaria</option>
                      <option hidden={props.hideSubstitution} value="Sustitucion">Sustitucion</option>
                    </Form.Control>
                    <ErrorMessage name="letterType">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group controlId="letterSustitution" hidden={!isSubstitution}>
                    <Form.Label>Carta a sustituir *</Form.Label>
                    <Form.Control
                      disabled={props.disabledAll}
                      as="select"
                      name="letterSustitution"
                      onChange={handleChange}
                      value={values.letterSustitution}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "letterSustitution"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {letterMovs?.map( (letterMov, idx) => (
                        <option key={idx} value={letterMov.letterMovId}>{letterMov.letterIden} {!props.typeModule && props.typeModule !== "Honorarios medicos" ? ` - $${letterMov.amount}` : ''}</option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="letterSustitution">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>
              <Row hidden={props.disabledAll}>
                <Col hidden={hideAmountLetter}>
                  <Form.Group controlId="amount">
                    <Form.Label>Importe Carta Autorizacion *</Form.Label>
                    <InputGroup className="mb-3">
                      <InputGroup.Prepend>
                        <InputGroup.Text id="basic-addon1">$</InputGroup.Text>
                      </InputGroup.Prepend>
                      <NumberFormat
                        fixedDecimalScale={true}
                        decimalScale={2}
                        value={values.amount}
                        onChange={handleChange}
                        name="amount"
                        thousandSeparator={true}
                        className={`form-control ${getInputClasses(
                          touched,
                          errors,
                          "amount"
                        )}`}
                      />
                    </InputGroup>
                    <ErrorMessage name="amount">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="letterIden">
                    <Form.Label>Numero de Carta *</Form.Label>
                    <Form.Control
                      name="letterIden"
                      readOnly="readonly"
                      value={values.letterIden}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "letterIden"
                      )}`}
                    >
                    </Form.Control>
                    <ErrorMessage name="letterIden">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row hidden={(!props.typeModule && props.typeModule !== "Honorarios medicos") || props.hideMedicField}>
                <Col>
                  <Form.Group controlId="doctorName">
                    <Form.Label>Medico tratante</Form.Label>
                    <Form.Control
                      disabled={props.disabledAll}
                      as="select"
                      name="doctorName"
                      value={values.doctorName}
                      onChange={handleChange}
                      className={`${getInputClasses(
                        touched,
                        errors,
                        "doctorName"
                      )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {props.medicalFees?.map( (medicalFee, idx) => (
                        <option key={idx} value={medicalFee.medicName}>{medicalFee.medicName}</option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="doctorName">
                      {(msg) => (
                        <Form.Control.Feedback type="invalid">
                          {msg}
                        </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row hidden={(!props.typeModule && props.typeModule !== "Honorarios medicos") || props.hideMedicField}>
                <Col>
                  <Form.Group controlId="doctorName">
                    <Form.Label>Medico Red AXA</Form.Label>
                    <div>
                      <Form.Check
                          inline
                          type="radio"
                          label="Sí"
                          name="isAxaMedic"
                          value="1"
                          checked={values.isAxaMedic === "1"}
                          onChange={ e => {
                            handleChange(e);
                            setShowDoctorNetworkAxa(true);
                          }}
                          disabled={props.disabledAll}
                          className={`${getInputClasses(touched, errors, "isAxaMedic")}`}
                      />
                      <Form.Check
                          inline
                          type="radio"
                          label="No"
                          name="isAxaMedic"
                          value="0"
                          checked={values.isAxaMedic === "0"}
                          onChange={ e => {
                            handleChange(e);
                            setShowDoctorNetworkAxa(false);
                          }}
                          disabled={props.disabledAll}
                          className={`${getInputClasses(touched, errors, "isAxaMedic")}`}
                      />
                    </div>
                    <ErrorMessage name="isAxaMedic">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Row hidden={!showDoctorNetworkAxa}>
                <Col>
                  <Form.Group controlId="medicalNetworkId">
                    <Form.Label>Selecciona el Nombre del Medico de Red</Form.Label>
                    <Form.Control
                        disabled={props.disabledAll}
                        as="select"
                        name="medicalNetworkId"
                        value={values.medicalNetworkId}
                        onChange={handleChange}
                        className={`${getInputClasses(
                            touched,
                            errors,
                            "medicalNetworkId"
                        )}`}
                    >
                      <option value="">Selecciona una opcion...</option>
                      {medicalNetworkDoctors?.map( (doctor, idx) => (
                          <option key={idx} value={doctor.id}>{doctor.personName}</option>
                      ))}
                    </Form.Control>
                    <ErrorMessage name="medicalNetworkId">
                      {(msg) => (
                          <Form.Control.Feedback type="invalid">
                            {msg}
                          </Form.Control.Feedback>
                      )}
                    </ErrorMessage>
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="primary"
                type="submit"
                disabled={isSubmitting}
                hidden={props.disabledAll}
              >
                {isSubmitting && (
                  <Spinner
                    as="span"
                    animation="border"
                    variant="dark"
                    size="sm"
                  />
                )}
                Subir Documento
              </Button>
            </Form>
          </>
        )}
      </Formik> }
    </>
  );
};

export default AuthLetters;
